<template>
  <div>
    <template v-if="userData.role_id == 1">

    <b-table
      striped
      hover
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="search"
      @filtered="onFiltered"
      class="mt-3"
      responsive
      v-if="userData.role_id == 1"
    >
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: field.key === 'action' ? '25%' : '' }"
        />
      </template>
      <template #cell(number)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(start_date)="data">
        {{ convertDate(data.item.start_date) }}
      </template>
      <template #cell(student_name)="data">
        <span v-if="data.item.user != null">{{ data.item.user.name }}</span>
      </template>
      <template #cell(notes)="data">
        <span v-if="data.item.presence != null">{{
          data.item.presence.notes
        }}</span>
      </template>
      <template #cell(status)="data">
        <span
          v-if="
            data.item.status == 1 &&
            data.item.is_absent == 1 &&
            data.item.start_date.split(' ')[0] != today()
          "
          class="text-danger font-weight-bold"
          >Tidak Hadir</span
        >
        <span
          v-if="
            data.item.status == 2 &&
            data.item.presence != null &&
            data.item.presence.is_presence == 2
          "
          class="text-primary font-weight-bold"
          >Hadir {{ data.item.presence.date.split(" ")[1] }}</span
        >
        <span
          v-if="
            data.item.status == 2 &&
            data.item.presence != null &&
            data.item.presence.is_presence == 1
          "
          class="text-info font-weight-bold"
          >Izin {{ data.item.presence.date.split(" ")[1] }}</span
        >
      </template>

      <template #cell(action)="data">
        <b-button
          size="sm"
          class="mr-1 btn-info"
          v-b-tooltip.hover
          title="Detail"
          @click="
            $router.push({
              path: '/presence/detail/' + data.item.presence.id,
            })
          "
          v-if="data.item.presence != null"
          ><i class="fas fa-eye px-0"></i
        ></b-button>
        <!-- <b-button
          size="sm"
          class="mr-1 btn-success"
          v-b-tooltip.hover
          title="Edit"
          @click="
            $router.push({
              path: '/presence/edit/' + data.item.presence.id,
            })
          "
          v-if="data.item.presence != null && data.item.presence.is_presence == 2"
          ><i class="fas fa-edit px-0"></i
        ></b-button> -->
        <!-- <b-button
          size="sm"
          class="mr-1 btn-success"
          v-b-tooltip.hover
          title="Edit"
          @click="handleUpdateAbsent(data.item.presence.id)"
          v-if="data.item.presence != null && data.item.presence.is_presence == 1"
          ><i class="fas fa-edit px-0"></i
        ></b-button> -->
        <!-- <b-button
          size="sm"
          class="btn-danger"
          v-b-tooltip.hover
          title="Hapus"
          @click="deleteData(data.item.presence.id)"
          v-if="
            data.item.presence != null &&
            data.item.presence.date.split(' ')[0] == getToday()
          "
          ><i class="fas fa-trash px-0"></i
        ></b-button> -->
      </template>
    </b-table>
    <b-pagination
      v-if="items.length != 0 && userData.role_id == 1"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      class="mt-4"
    ></b-pagination>
    </template>
    <template v-if="userData.role_id == 2">
    <b-table
      striped
      hover
      :items="items"
      :fields="fields"
      class="mt-3"
      responsive
      v-if="userData.role_id == 2"
    >
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: field.key === 'action' ? '25%' : '' }"
        />
      </template>
      <template #cell(number)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(start_date)="data">
        {{ convertDate(data.item.start_date) }}
      </template>
      <template #cell(student_name)="data">
        <span v-if="data.item.user != null">{{ data.item.user.name }}</span>
      </template>
      <template #cell(notes)="data">
        <span v-if="data.item.presence != null">{{
          data.item.presence.notes
        }}</span>
      </template>
      <template #cell(status)="data">
        <span
          v-if="
            data.item.status == 1 &&
            data.item.is_absent == 1 &&
            data.item.start_date.split(' ')[0] != today()
          "
          class="text-danger font-weight-bold"
          >Tidak Hadir</span
        >
        <span
          v-if="
            data.item.status == 2 &&
            data.item.presence != null &&
            data.item.presence.is_presence == 2
          "
          class="text-primary font-weight-bold"
          >Hadir {{ data.item.presence.date.split(" ")[1] }}</span
        >
        <span
          v-if="
            data.item.status == 2 &&
            data.item.presence != null &&
            data.item.presence.is_presence == 1
          "
          class="text-info font-weight-bold"
          >Izin {{ data.item.presence.date.split(" ")[1] }}</span
        >
      </template>

      <template #cell(action)="data">
        <b-button
          size="sm"
          class="mr-1 btn-info"
          v-b-tooltip.hover
          title="Detail"
          @click="
            $router.push({
              path: '/presence/detail/' + data.item.presence.id,
            })
          "
          v-if="data.item.presence != null"
          ><i class="fas fa-eye px-0"></i
        ></b-button>
        <b-button
          size="sm"
          class="mr-1 btn-success"
          v-b-tooltip.hover
          title="Edit"
          @click="
            $router.push({
              path: '/presence/edit/' + data.item.presence.id,
            })
          "
          v-if="data.item.presence != null && data.item.presence.is_presence == 2"
          ><i class="fas fa-edit px-0"></i
        ></b-button>
        <b-button
          size="sm"
          class="mr-1 btn-success"
          v-b-tooltip.hover
          title="Edit"
          @click="handleUpdateAbsent(data.item.presence.id)"
          v-if="data.item.presence != null && data.item.presence.is_presence == 1"
          ><i class="fas fa-edit px-0"></i
        ></b-button>
        <b-button
          size="sm"
          class="btn-danger"
          v-b-tooltip.hover
          title="Hapus"
          @click="deleteData(data.item.presence.id)"
          v-if="
            data.item.presence != null &&
            data.item.presence.date.split(' ')[0] == getToday()
          "
          ><i class="fas fa-trash px-0"></i
        ></b-button>
      </template>
    </b-table>
    <b-pagination
      v-if="items.length != 0 && userData.role_id == 1"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      class="mt-4"
    ></b-pagination>
    </template>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { getUser, setUser } from "@/core/services/jwt.service.js";
import module from "@/core/modules/CrudModule.js";
import { WebCam } from "vue-web-cam";
import api from "@/core/services/api.service.js";

export default {
  props:{
      myClassGroup: Object,
      myClassMates: Array,
  },
  components: {
    "vue-web-cam": WebCam,
  },
  name: "master-formats",
  data() {
    return {
      // camera
      camera: null,
      deviceId: null,
      devices: [],
      img: null,
      baseImg: null,
      content: "list",
      // form
      form: {
        student_id: getUser().id,
        presence_type_id: 0,
        date: "",
        photo: "",
        late: "",
        student_name: getUser().name,
      },
      // filter
      filter: {
        school_year_id: "",
        now: new Date().toISOString().substr(0, 10),
        month:
          new Date().toISOString().substr(0, 4) +
          "-0" +
          (new Date().getMonth() + 1),
        start_periode: "",
        class_group_id: ''
      },
      // search
      search: null,
      // Pagination
      perPage: 31,
      currentPage: 1,
      totalRows: 1,
      fields: [
        {
          key: "number",
          label: "No",
          sortable: true,
        },
        {
          key: "start_date",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "student_name",
          label: "Nama Siswa",
          sortable: true,
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
        },
        {
          key: "notes",
          label: "Keterangan",
          sortable: true,
        },
        {
          key: "name",
          label: "Jadwal",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          tdClass: "nowrap",
        },
      ],
      items: [],
      oldItem: [],
      // date&time
      time: "",
      date: "",
      updateTimeStatus: true,
      // data absen hari ini
      presence_today: [],
      // other
      capture_status: false,
      presence_today_status: false,
      is_presence_today: true,
      dataLoaded: false,
      userData: getUser(),
    };
  },
  methods: {
   async handleUpdateAbsent(id){
      let presence = await module.get('api/student-presences/' + id)
      // If Data Not Found
      if (presence == null) {
        // Redirect To List
        // this.$router.push('/masters/presence')
      } else {
        presence['_method'] = 'put'
        this.$root.$emit('handleUpdateAbsent', presence)
      }
    },
    getToday() {
      //("get Today", new Date().toISOString().substr(0, 10));
      return new Date().toISOString().substr(0, 10);
    },
    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.pagination();
    },

    async pagination(userId, lastIndex) {
      let currentYear = new Date().toISOString().substr(0, 4);
      let filterParams = `&now=${this.filter.now}&class_group_id${this.filter.class_group_id}`;
      let response = await module.paginate(
          
        `api/academic-calendars/presences/users/${userId}/students/today`,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );

      this.oldItem.push(response.data);
      for(let a = 0; a < response.data.length; a++){
        response.data[a].number = a + 1
        this.items.push(response.data[a])
      }

      //("item", this.items)
      if(userId == lastIndex){
        this.items.sort((a, b) => (a.start_date > b.start_date && a.name > b.name? 1 : -1))
        this.totalRows = this.items.length
        setTimeout(() => {
          this.fixItem()
        }, 1000);
      }
    //   let pagination = response.meta.pagination;
    //   this.totalRows = pagination.total;

    //   let a, b;
    //   b = this.perPage * (this.currentPage - 1) + 1;
    //   for (a = 0; a < response.data.length; a++) {
    //     response.data[a].number = b + a;
    //   }

    //   this.items = response.data;
    //   this.dataLoaded = true;
    },

    filterByName() {
      this.pagination();
    },

    today() {
      //("today", new Date().toISOString().substr(0, 10));
      return new Date().toISOString().substr(0, 10);
    },

    convertDate(date) {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      let localdata = new Date(date).toLocaleDateString("id-ID", options);
      return localdata;
    },

    async getMyClassMates() {
    //   api
    //     .get(`api/class-groups/my-students/users/${getUser().id}`)
    //     .then((response) => {
    //       let my_students = response.data;
    //       for (let a = 0; a < my_students.length; a++) {
    //         // let response = await module.paginate(
    //         // `api/academic-calendars/presences/users/${my_students[a]}/students`,
    //         // `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
    //         // );
    //         // //("response["+a+"]", response)

    //         this.paginationTeacher(my_students[a], my_students[my_students.length - 1]);

    //         // if (a + 1 == my_students.length) {
              
    //         // }
    //       }
    //     })
    //     .catch((error) => {
    //       //("error", error.response);
    //     });
        for(let a = 0; a < this.myClassMates.length; a++){
            this.pagination(this.myClassMates[a].id, this.myClassMates[this.myClassMates.length - 1].id)
        }
    },

    // teacher method
    async getMyStudent() {
      api
        .get(
          `api/class-groups/my-students/users/${getUser().id}`,
          `?class_group_id=${this.filter.class_group_id}`
        )
        .then((response) => {
          let my_students = response.data;
          for (let a = 0; a < my_students.length; a++) {
            // let response = await module.paginate(
            // `api/academic-calendars/presences/users/${my_students[a]}/students`,
            // `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
            // );
            // //("response["+a+"]", response)

            this.paginationTeacher(my_students[a], my_students[my_students.length - 1]);

            // if (a + 1 == my_students.length) {
              
            // }
          }
        })
        .catch((error) => {
          //("error", error.response);
        });
    },

    onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },

    async paginationTeacher(userId, lastIndex) {
      let currentYear = new Date().toISOString().substr(0, 4);
      let filterParams = `&now=${this.filter.now}&school_year_id=${this.filter.school_year_id}&month=${this.filter.month}&start_periode=${this.filter.start_periode}&class_group_id=${this.filter.class_group_id}`;
      let response = await module.paginate(
        `api/academic-calendars/presences/users/${userId}/teachers/today`,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      //("response[" + userId + "]", response.data);

      // this.items.concat(response.data)
      this.oldItem.push(response.data);
      for(let a = 0; a < response.data.length; a++){
        response.data[a].number = a + 1
        this.items.push(response.data[a])
      }

      //("item", this.items)
      if(userId == lastIndex){
        this.items.sort((a, b) => (a.start_date > b.start_date && a.name > b.name? 1 : -1))
        this.totalRows = this.items.length
        setTimeout(() => {
          this.fixItem()
        }, 1000);
      }

    },

    fixItem(){
      this.items.sort((a, b) => (a.start_date > b.start_date ? 1 : -1))
      // this.totalRows = this.items.length
      // for(let a = 0; a < this.items.length; a++){
      //   this.items[a].number = a + 1
      //   // //("fixed-"+a, this.items[a].number)
      //   if(a + 1 == this.items.length){
      //     this.dataLoaded = true
      //     // let x = list.sort((a, b) => (a.name > b.name ? 1 : -1));
      //   }
      // }
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.deleteWithResponse(
        "api/student-presences/" + id
      );
      //("result", result);
      // If Deleted
      if (result) {
        this.pagination();
        this.$emit("delete");
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Master", route: "/masters/settings" },
      { title: "Absensi" },
    ]);
    if (getUser().role_id == 2) {
      this.getMyClassMates();
    } else {
      this.getMyStudent();
    }
    // this.pagination()

    this.$root.$on("refreshPresenceCalendar", () => {
      if (getUser().role_id == 2) {
        this.pagination();
      }
    });

    this.$root.$on("filterPresenceByName", (search) => {
      //("search", search)
      this.search = search
      // this.paginationTeacher()
    });

    this.$root.$on("filterByClassGroup", (filter) => {
      this.filter.class_group_id = filter.class_group_id
      this.items = []
      this.getMyStudent()
    });

    this.$root.$on("filterPresenceByMonth", (filter) => {
      this.filter.month = filter.month;
      this.filter.start_periode = filter.start_periode;
    //   if (getUser().role_id == 2) {
    //     this.getMyClassMates();
    //   }else{
    //     this.items = []
    //     this.getMyStudent()
    //   }
    });
  },
  computed: {
    device: function () {
      return this.devices.find((n) => n.deviceId === this.deviceId);
    },
  },
  watch: {
    camera: function (id) {
      this.deviceId = id;
    },
    devices: function () {
      // Once we have a list select the first one
      const [first, ...tail] = this.devices;
      if (first) {
        this.camera = first.deviceId;
        this.deviceId = first.deviceId;
      }
    },
    myClassGroup: function (newVal, oldVal) {
      // watch it
      this.filter.class_group_id = this.myClassGroup.id
    },
    myClassMates: function (newVal, oldVal) {
      // watch it
      this.getMyClassMates()
    },
  },
};
</script>

<style>
.nowrap {
  white-space: nowrap;
}

.sr-only {
  width: 0px !important;
}
</style>
