<template>
  <v-app>
    <div v-if="dataLoaded">
      <!-- Header Alert -->
      <b-alert
        show
        variant="light"
        class="alert alert-custom alert-white alert-shadow fade show gutter-b"
      >
        <div class="alert-icon">
          <span class="svg-icon svg-icon-lg">
            <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
          </span>
        </div>
        <div class="alert-text">Daftar <b>Riwayat Kehadiran</b></div>
      </b-alert>
      <b-alert
        show
        variant="light"
        class="
          alert alert-custom alert-white alert-shadow
          fade
          show
          gutter-b
          py-2
        "
        v-if="
          capture_status == false &&
          is_presence_today == true &&
          presence_today_status == false &&
          userData.role_id == 2 &&
          confirmPresence == true
        "
      >
        <div class="col-md-2 p-0 mr-4">
          <vue-web-cam
            ref="webcam"
            :device-id="deviceId"
            width="100%"
            height="100%"
            @started="onStarted"
            @stopped="onStopped"
            @error="onError"
            @cameras="onCameras"
            @camera-change="onCameraChange"
            v-if="capture_status == false && is_presence_today == true"
          />
          <img
            :src="baseImg"
            height="100%"
            width="100%"
            v-if="capture_status"
          />
        </div>
        <div class="alert-text" v-if="presence_today_status == false">
          <b>{{ date }}, {{ time }}</b>
        </div>
        <div class="mt-md-9 mt-sm-4" v-if="presence_today_status == true">
          <span class="text-h5"
            >Kamu sudah melakukan presensi hari ini pada {{ absentTime }}</span
          >
          <!-- <span class="text-h4">Hadir {{absentTime}}</span> -->
        </div>
        <b-button
          squared
          variant="primary"
          class="h-50 mb-auto mt-auto mr-2"
          @click.stop.prevent="formOnsubmitPresence"
          v-if="capture_status == false"
          ><i class="fas fa-camera"></i> Hadir</b-button
        >
        <b-button
          squared
          variant="danger"
          class="h-50 mb-auto mt-auto"
          @click.stop.prevent="showModalAbsent"
          v-if="capture_status == false"
          >Izin tidak hadir</b-button
        >
      </b-alert>
      <v-alert
        prominent
        type="info"
        color="#1BC5BD"
        v-if="presence_today_status == true && is_presence == 2"
      >
        <v-row align="center">
          <v-col class="grow">
            Kamu telah berhasil melakukan presensi hari ini pada
            {{ absentTime }}
          </v-col>
          <v-col class="shrink">
            <v-btn
              @click="$router.push(`/presence/detail/${presence_today.id}`)"
              >Detail</v-btn
            >
          </v-col>
        </v-row>
      </v-alert>
      <v-alert
        prominent
        type="info"
        color="info"
        v-if="presence_today_status == true && is_presence == 1"
      >
        <v-row align="center">
          <v-col class="grow"> Kamu izin untuk tidak sekolah hari ini </v-col>
          <v-col class="shrink">
            <v-btn
              @click="$router.push(`/presence/detail/${presence_today.id}`)"
              >Detail</v-btn
            >
          </v-col>
        </v-row>
      </v-alert>

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <!-- filter -->
              <div class="row justify-content-end">
                <div class="col-md-3" v-if="order_tab_active != 0">
                  <b-form-select
                    v-model="filter.month"
                    :options="monthOption"
                    @input="filterByMonth"
                  ></b-form-select>
                </div>
                <div class="col-md-3" v-if="userData.role_id == 1">
                  <b-form-group
                    id="input-group-class-groups"
                    label-for="input-class-groups"
                  >
                    <treeselect
                      v-model="filter.class_group_id"
                      :multiple="false"
                      placeholder="Pilih Kelompok Kelas"
                      :options="class_groups"
                      @input="filterByClassGroup"
                    />
                  </b-form-group>
                </div>
                <div class="col-md-4" v-if="userData.role_id == 1">
                  <b-input-group class="mb-2">
                    <b-form-select
                      v-model="search"
                      :options="optionsName"
                      @input="filterByName"
                    ></b-form-select>
                    <b-input-group-append>
                      <button class="btn btn-danger" @click="filterReset">
                        Reset
                      </button>
                    </b-input-group-append>
                  </b-input-group>
                </div>
              </div>

              <b-tabs content-class="mt-3" @input="handleTab">
                <b-tab title="Rekap Hari Ini" v-if="presenceTodayView" active>
                  <TablePresenceToday
                    :myClassGroup="my_class_group"
                    :myClassMates="my_class_mates"
                  />
                </b-tab>
                <b-tab
                  :title="
                    userData.role_id == 2 ? 'Rekap Absen Saya' : 'Rekap Absen'
                  "
                >
                  <Table @delete="handleDelete" />
                </b-tab>
                <b-tab title="Kalender" v-if="userData.role_id == 2">
                  <Calendar />
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ModalAbsent
      @cancelAbsent="$bvModal.hide('modal-absent')"
      @successAbsent="successAbsent"
      :data="update_absent"
      :route="routeAbsent"
      :purpose="purposeAbsent"
    />
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { getUser, setUser } from "@/core/services/jwt.service.js";
import module from "@/core/modules/CrudModule.js";
import { WebCam } from "vue-web-cam";
import Table from "@/view/components/presence/Table.vue";
import TablePresenceToday from "@/view/components/presence/TablePresenceToday.vue";
import Calendar from "@/view/components/presence/Calendar.vue";
import ModalAbsent from "@/view/components/general/ModalAbsent.vue";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import EasyCamera from 'easy-vue-camera';

export default {
  components: {
    "vue-web-cam": WebCam,
    Table,
    TablePresenceToday,
    Calendar,
    ModalAbsent,
    'v-easy-camera': EasyCamera
  },
  name: "master-formats",
  data() {
    return {
      // camera
      camera: null,
      deviceId: null,
      devices: [],
      img: null,
      baseImg: null,
      content: "list",
      // form
      form: {
        student_id: getUser().id,
        presence_type_id: 0,
        date: "",
        photo: "",
        late: "",
        student_name: getUser().name,
        is_presence: 2,
      },
      // filter
      filter: {
        school_year_id: "",
        now: new Date().toISOString().substr(0, 10),
        month:
          new Date().toISOString().substr(0, 4) +
          "-0" +
          (new Date().getMonth() + 1),
        start_periode: "",
        class_group_id: "",
      },
      // search
      search: null,
      optionsName: [],
      optionsNameForm: {
        value: "",
        text: "",
      },
      // Pagination
      perPage: 31,
      currentPage: 1,
      totalRows: 1,
      fields: [
        {
          key: "number",
          label: "No",
          sortable: true,
        },
        {
          key: "start_date",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
        },
        {
          key: "notes",
          label: "Keterangan",
          sortable: true,
        },
        {
          key: "name",
          label: "Kegiatan",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          tdClass: "nowrap",
        },
      ],
      items: [],
      // date&time
      time: "",
      date: "",
      updateTimeStatus: true,
      // data absen hari ini
      presence_today: [],
      // monthOption
      yearOption: new Date().toISOString().substr(0, 4),
      // monthOption: [
      //     { value: '-1', text: 'Semua Bulan' },
      //     { value: new Date().toISOString().substr(0, 4) + '-01', text: 'Januari' },
      //     { value: new Date().toISOString().substr(0, 4) + '-02', text: 'Februari' },
      //     { value: new Date().toISOString().substr(0, 4) + '-03', text: 'Maret' },
      //     { value: new Date().toISOString().substr(0, 4) + '-04', text: 'April' },
      //     { value: new Date().toISOString().substr(0, 4) + '-05', text: 'Mei' },
      //     { value: new Date().toISOString().substr(0, 4) + '-06', text: 'Juni' },
      //     { value: new Date().toISOString().substr(0, 4) + '-07', text: 'Juli' },
      //     { value: new Date().toISOString().substr(0, 4) + '-08', text: 'Agustus' },
      //     { value: new Date().toISOString().substr(0, 4) + '-09', text: 'September' },
      //     { value: new Date().toISOString().substr(0, 4) + '-10', text: 'Oktober' },
      //     { value: new Date().toISOString().substr(0, 4) + '-11', text: 'November' },
      //     { value: new Date().toISOString().substr(0, 4) + '-12', text: 'Desember' },
      // ],
      monthOption: [{ value: "-1", text: "Semua Bulan" }],
      monthOptionForm: {
        value: "",
        text: "",
      },
      months: [],
      // route
      routeAbsent: `api/student-presences`,
      purposeAbsent: "add",
      // other
      capture_status: true,
      presence_today_status: false,
      is_presence_today: true,
      dataLoaded: false,
      userData: getUser(),
      absentTime: "",
      is_presence: "",
      class_groups: [],
      update_absent: {},
      my_class_group: {},
      my_class_mates: [],
      order_tab_active: 0,
      users: [],
      // access
      presenceTodayView: false,
      confirmPresence: false,
    };
  },
  methods: {
    handleTab(evt) {
      //("evt tab", evt);
      this.order_tab_active = evt;
    },
    async getSChoolYearActive() {
      let response = await module.paginate("api/school-years/month");

      this.months = response.data[0].months;
 
      for (let a = 0; a < this.months.length; a++) {
        this.monthOptionForm.value =
          this.months[a].year + "-" + this.months[a].order_month_natural;
        if (this.months[a].order_month == "1") {
          this.monthOptionForm.text = "Januari " + this.months[a].year;
        }
        if (this.months[a].order_month == "2") {
          this.monthOptionForm.text = "Februari " + this.months[a].year;
        }
        if (this.months[a].order_month == "3") {
          this.monthOptionForm.text = "Maret " + this.months[a].year;
        }
        if (this.months[a].order_month == "4") {
          this.monthOptionForm.text = "April " + this.months[a].year;
        }
        if (this.months[a].order_month == "5") {
          this.monthOptionForm.text = "Mei " + this.months[a].year;
        }
        if (this.months[a].order_month == "6") {
          this.monthOptionForm.text = "Juni " + this.months[a].year;
        }
        if (this.months[a].order_month == "7") {
          this.monthOptionForm.text = "Juli " + this.months[a].year;
        }
        if (this.months[a].order_month == "8") {
          this.monthOptionForm.text = "Agustus " + this.months[a].year;
        }
        if (this.months[a].order_month == "9") {
          this.monthOptionForm.text = "September " + this.months[a].year;
        }
        if (this.months[a].order_month == "10") {
          this.monthOptionForm.text = "Oktober " + this.months[a].year;
        }
        if (this.months[a].order_month == "11") {
          this.monthOptionForm.text = "November " + this.months[a].year;
        }
        if (this.months[a].order_month == "12") {
          this.monthOptionForm.text = "Desember " + this.months[a].year;
        }
        let clone = { ...this.monthOptionForm };
        this.monthOption.push(clone);
      }
  
    },

    showModalAbsent() {
      this.$bvModal.show("modal-absent");
    },

    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.pagination();
    },

    async getMyClassGroup() {
      let currentYear = new Date().toISOString().substr(0, 4);
      let filterParams = `now=${this.filter.now}`;
      let response = await module.getFullResponse(
        `api/class-groups/my-class-groups/users/${getUser().id}/students`,
        `?${filterParams}`
      );

  
      this.my_class_group = response.data.data;
      this.my_class_mates = response.data.meta.classmates;
    },

    async pagination() {
      let currentYear = new Date().toISOString().substr(0, 4);
      let filterParams = `&now=${this.filter.now}&school_year_id=${
        this.filter.school_year_id
      }&month=${currentYear + "-0" + this.filter.month}`;
      let response = await module.paginate(
        `api/academic-calendars/presences/${getUser().id}`,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
    },

    async checkPresenceToday() {
      let currentTime = new Date().toISOString().substring(0, 10);
      let filterParams = `presence_today=${currentTime}&student_id=${
        getUser().id
      }`;
      let response = await module.paginate(
        `api/student-presences`,
        `?${filterParams}`
      );

      //("presence today", response.data);
      if (response.data.length > 0) {
        this.presence_today_status = true;
        this.presence_today = response.data[0];
        this.baseImg = response.data[0].photo;
        this.capture_status = true;
        this.absentTime = response.data[0].date.split(" ")[1];
        this.is_presence = response.data[0].is_presence;
      }
    },

    async checkIsPresenceToday() {
      let currentTime = new Date().toISOString().substring(0, 10);
      let filterParams = `is_presence_today=${currentTime}`;
      let response = await module.paginate(
        `api/academic-calendars`,
        `?${filterParams}`
      );

      this.dataLoaded = true;
      //("is presence today", response.data);
      if (response.data.length > 0) {
        if (response.data[0].is_absent == 0) {
          this.is_presence_today = false;
          this.updateTimeStatus = false;
        }
      }
    },

    filterByName() {
      this.$root.$emit("filterPresenceByName", this.search);
    },

    filterByMonth(evt) {
      //(evt);
      this.filter.month = evt;
      this.filter.start_periode = this.months[0].date;
      this.$root.$emit("filterPresenceByMonth", this.filter);
    },

    filterReset(){
      // filter: {
      //   school_year_id: "",
      //   now: new Date().toISOString().substr(0, 10),
      //   month:
      //     new Date().toISOString().substr(0, 4) +
      //     "-0" +
      //     (new Date().getMonth() + 1),
      //   start_periode: "",
      //   class_group_id: "",
      // },

      this.search = null
      this.filter.school_year_id = ''
      this.filter.now = new Date().toISOString().substr(0, 10)
      this.month = new Date().toISOString().substr(0, 4) + "-0" + (new Date().getMonth() + 1)
      this.start_periode = ''
      this.class_group_id = ''
      this.$root.$emit("filterReset", this.filter);
    },

    setTime() {
      let ct = new Date();
      this.time =
        this.zeroPadding(ct.getHours(), 2) +
        ":" +
        this.zeroPadding(ct.getMinutes(), 2) +
        ":" +
        this.zeroPadding(ct.getSeconds(), 2);
    },

    setDate() {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      this.date = new Date().toLocaleDateString("id-ID", options);
    },

    zeroPadding(num, digit) {
      var zero = "";
      for (var i = 0; i < digit; i++) {
        zero += "0";
      }
      return (zero + num).slice(-digit);
    },

    updateTime() {
      if (this.updateTimeStatus == true) {
        setTimeout(() => {
          if (this.updateTimeStatus == true) {
            this.setTime();
            this.updateTime();
          }
        }, 1000);
      }
    },

    async getClassGroupOption() {
      let response = await module.setTreeSelect(
        `api/class-groups/available/my-class-groups/users/${getUser().id}`,
        `?now=${this.filter.now}`
      );
      //("classgroup", response);
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.class_groups = response.data;
        this.class_groups.unshift({
          label: "Pilih Kelompok Kelas",
          id: "",
          isDisabled: true,
        });
      }
    },

    async getMyStudent() {
      let filterParams = `&class_group_id=${this.filter.class_group_id}&role_id=2`;
      let response = await module.paginate(
        `api/users/my-students/${getUser().id}}`,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );

      this.users = response.data;
    
      for (let a = 0; a < this.users.length; a++) {
        this.optionsNameForm.text = this.users[a].name;
        this.optionsNameForm.value = this.users[a].name;

        let clone = { ...this.optionsNameForm };
        this.optionsName.push(clone);

        if (a + 1 == this.users.length) {
          this.optionsName.unshift({
            text: "Pilih Nama Siswa",
            value: null,
            disabled: true,
          });
        }
      }
    },

   async filterByClassGroup(evt) {
      //(evt);
      if (!evt) {
        this.filter.class_group_id = await "";
        this.$root.$emit("filterByClassGroup", this.filter);
        this.optionsName = []
        this.getMyStudent()
      } else {
        this.filter.class_group_id = await evt;
        //("ok", this.filter.class_group_id);
        this.$root.$emit("filterByClassGroup", this.filter);
        this.optionsName = []
        this.getMyStudent()
      }
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("api/formats/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    handleDelete() {
      this.presence_today_status = false;
      this.capture_status = false;
      this.is_presence = "";
      setTimeout(() => {
        this.onStart();
      }, 100);
    },
    // form submit
    async formOnsubmitPresence() {
      // stop time
      this.updateTimeStatus = false;
      await this.onCapture();
      // let currentTime = new Date().toTimeString();

      this.form.photo = this.dataURItoBlob(this.baseImg);
      this.form.late = 0;
      this.form.is_presence = 2;

      let mergeTime = this.date + " " + this.time;
      let currentDate = new Date().toISOString().substr(0, 10);
      let currentTime = new Date().toTimeString().substr(0, 8);
      this.form.date = currentDate + " " + currentTime;

      // if(currentTime >= this.presence_type_display.open_time && currentTime <= this.presence_type_display.close_time){
      //   // this.form.presence_type_id = this.form.presence_type_id
      //   this.form.late = 0
      //   //("betul")
      // }else{
      //   // this.form.presence_type_id = this.form.presence_type_id
      //   this.form.late = this.diff(this.presence_type_display.close_time, currentTime)
      //   //("salah disini")
      // }

      let formData = new FormData();
      for (const [key, value] of Object.entries(this.form)) {
        formData.append(key, value);
      }
      // Make Request
      // classrooms/{classroomId}/student-presences
      let response = await module.submit(formData, `api/student-presences`);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        // this.error = validation.setValidationError(
        //   this.error,
        //   response.error.errors
        // );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.presence_today_status = true;
        this.absentTime = response.success.item.original.date.split(" ")[1];
        this.presence_today = response.success.item.original;
        //("presence today", this.presence_today);
        this.$root.$emit("refreshPresenceCalendar");
        this.is_presence = 2;
      }

      //("form", this.form);
    },
    successAbsent(response) {
      this.presence_today_status = true;
      this.absentTime = response.success.item.original.date.split(" ")[1];
      this.presence_today = response.success.item.original;
      this.is_presence = 1;
      //("presence today", this.presence_today);
      this.$root.$emit("refreshPresenceCalendar");
      this.$bvModal.hide("modal-absent");
      this.routeAbsent = "api/student-presences";
    },
    // convert base64 to image
    dataURItoBlob(dataURI) {
      var byteString;
      if (dataURI.split(",")[0].indexOf("base64") >= 0)
        byteString = atob(dataURI.split(",")[1]);
      else byteString = unescape(dataURI.split(",")[1]);

      // separate out the mime component
      var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

      // write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ia], {
        type: mimeString,
      });
    },

    // camera method
    cameraActivate(){
    setTimeout(() => {
        this.onStart();
      }, 4000);
      this.capture_status = false
    },
    onCapture() {
      //('ref', this.$refs)
      this.baseImg = this.$refs.webcam.capture();
      this.img = this.dataURItoBlob(this.baseImg);
      this.form.photo = this.dataURItoBlob(this.baseImg);
      this.capture_status = true;
      //("form", this.form);
    },
    onStarted(stream) {
      //("On Started Event", stream);
    },
    onStopped(stream) {
      //("On Stopped Event", stream);
    },
    onStop() {
      this.$refs.webcam.stop();
    },
    onStart() {
      this.$refs.webcam.start();
    },
    onError(error) {
      //("On Error Event", error);
    },
    onCameras(cameras) {
      this.devices = cameras;
      //("On Cameras Event", cameras);
    },
    onCameraChange(deviceId) {
      this.deviceId = deviceId;
      this.camera = deviceId;
      //("On Camera Change Event", deviceId);
    },

    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "4001") {
          this.presenceTodayView = true
        }
        if (access_right[a] == "4002") {
          this.confirmPresence = true
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Master", route: "/masters/settings" },
      { title: "Absensi" },
    ]);
    // this.pagination();
    this.updateTime();
    this.setDate();
    this.checkPresenceToday();
    this.checkIsPresenceToday();
    this.getSChoolYearActive();
    this.getClassGroupOption();
    this.getMyStudent();
    this.checkAccess();
    this.cameraActivate();

    if (getUser().role_id == 2) {
      this.getMyClassGroup();
    }

    this.$root.$on("handleUpdateAbsent", (presence) => {
      //("update", presence);
      this.update_absent = presence;
      this.routeAbsent = `api/student-presences/${presence.id}`;
      this.purposeAbsent = `edit`;
      this.$bvModal.show("modal-absent");
    });
  },
  computed: {
    device: function () {
      return this.devices.find((n) => n.deviceId === this.deviceId);
    },
  },
  watch: {
    camera: function (id) {
      this.deviceId = id;
    },
    devices: function () {
      // Once we have a list select the first one
      const [first, ...tail] = this.devices;
      if (first) {
        this.camera = first.deviceId;
        this.deviceId = first.deviceId;
      }
    },
  },
};
</script>

<style>
.nowrap {
  white-space: nowrap;
}
</style>
