var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.userData.role_id == 1)?[(_vm.userData.role_id == 1)?_c('b-table',{staticClass:"mt-3",attrs:{"striped":"","hover":"","items":_vm.items,"fields":_vm.fields,"current-page":_vm.currentPage,"per-page":_vm.perPage,"filter":_vm.search,"responsive":""},on:{"filtered":_vm.onFiltered},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({ width: field.key === 'action' ? '25%' : '' })})})}},{key:"cell(number)",fn:function(data){return [_vm._v(" "+_vm._s(data.index + 1)+" ")]}},{key:"cell(start_date)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.convertDate(data.item.start_date))+" ")]}},{key:"cell(student_name)",fn:function(data){return [(data.item.user != null)?_c('span',[_vm._v(_vm._s(data.item.user.name))]):_vm._e()]}},{key:"cell(notes)",fn:function(data){return [(data.item.presence != null)?_c('span',[_vm._v(_vm._s(data.item.presence.notes))]):_vm._e()]}},{key:"cell(status)",fn:function(data){return [(
            data.item.status == 1 &&
            data.item.is_absent == 1 &&
            data.item.start_date.split(' ')[0] != _vm.today()
          )?_c('span',{staticClass:"text-danger font-weight-bold"},[_vm._v("Tidak Hadir")]):_vm._e(),(
            data.item.status == 2 &&
            data.item.presence != null &&
            data.item.presence.is_presence == 2
          )?_c('span',{staticClass:"text-primary font-weight-bold"},[_vm._v("Hadir "+_vm._s(data.item.presence.date.split(" ")[1]))]):_vm._e(),(
            data.item.status == 2 &&
            data.item.presence != null &&
            data.item.presence.is_presence == 1
          )?_c('span',{staticClass:"text-info font-weight-bold"},[_vm._v("Izin "+_vm._s(data.item.presence.date.split(" ")[1]))]):_vm._e()]}},{key:"cell(action)",fn:function(data){return [(data.item.presence != null)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-1 btn-info",attrs:{"size":"sm","title":"Detail"},on:{"click":function($event){return _vm.$router.push({
              path: '/presence/detail/' + data.item.presence.id,
            })}}},[_c('i',{staticClass:"fas fa-eye px-0"})]):_vm._e()]}}],null,false,2423481231)}):_vm._e(),(_vm.items.length != 0 && _vm.userData.role_id == 1)?_c('b-pagination',{staticClass:"mt-4",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()]:_vm._e(),(_vm.userData.role_id == 2)?[(_vm.userData.role_id == 2)?_c('b-table',{staticClass:"mt-3",attrs:{"striped":"","hover":"","items":_vm.items,"fields":_vm.fields,"responsive":""},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({ width: field.key === 'action' ? '25%' : '' })})})}},{key:"cell(number)",fn:function(data){return [_vm._v(" "+_vm._s(data.index + 1)+" ")]}},{key:"cell(start_date)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.convertDate(data.item.start_date))+" ")]}},{key:"cell(student_name)",fn:function(data){return [(data.item.user != null)?_c('span',[_vm._v(_vm._s(data.item.user.name))]):_vm._e()]}},{key:"cell(notes)",fn:function(data){return [(data.item.presence != null)?_c('span',[_vm._v(_vm._s(data.item.presence.notes))]):_vm._e()]}},{key:"cell(status)",fn:function(data){return [(
            data.item.status == 1 &&
            data.item.is_absent == 1 &&
            data.item.start_date.split(' ')[0] != _vm.today()
          )?_c('span',{staticClass:"text-danger font-weight-bold"},[_vm._v("Tidak Hadir")]):_vm._e(),(
            data.item.status == 2 &&
            data.item.presence != null &&
            data.item.presence.is_presence == 2
          )?_c('span',{staticClass:"text-primary font-weight-bold"},[_vm._v("Hadir "+_vm._s(data.item.presence.date.split(" ")[1]))]):_vm._e(),(
            data.item.status == 2 &&
            data.item.presence != null &&
            data.item.presence.is_presence == 1
          )?_c('span',{staticClass:"text-info font-weight-bold"},[_vm._v("Izin "+_vm._s(data.item.presence.date.split(" ")[1]))]):_vm._e()]}},{key:"cell(action)",fn:function(data){return [(data.item.presence != null)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-1 btn-info",attrs:{"size":"sm","title":"Detail"},on:{"click":function($event){return _vm.$router.push({
              path: '/presence/detail/' + data.item.presence.id,
            })}}},[_c('i',{staticClass:"fas fa-eye px-0"})]):_vm._e(),(data.item.presence != null && data.item.presence.is_presence == 2)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-1 btn-success",attrs:{"size":"sm","title":"Edit"},on:{"click":function($event){return _vm.$router.push({
              path: '/presence/edit/' + data.item.presence.id,
            })}}},[_c('i',{staticClass:"fas fa-edit px-0"})]):_vm._e(),(data.item.presence != null && data.item.presence.is_presence == 1)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-1 btn-success",attrs:{"size":"sm","title":"Edit"},on:{"click":function($event){return _vm.handleUpdateAbsent(data.item.presence.id)}}},[_c('i',{staticClass:"fas fa-edit px-0"})]):_vm._e(),(
            data.item.presence != null &&
            data.item.presence.date.split(' ')[0] == _vm.getToday()
          )?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn-danger",attrs:{"size":"sm","title":"Hapus"},on:{"click":function($event){return _vm.deleteData(data.item.presence.id)}}},[_c('i',{staticClass:"fas fa-trash px-0"})]):_vm._e()]}}],null,false,659813680)}):_vm._e(),(_vm.items.length != 0 && _vm.userData.role_id == 1)?_c('b-pagination',{staticClass:"mt-4",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }